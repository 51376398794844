
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient, ICustomColumn, IForm,
} from '@/modules/scheduled-reports/interfaces';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import ClusterService, { ClusterServiceS } from '../cluster.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    form: IForm = {} as IForm;

    get properties(): IProperties {
        return {
            dataType: DATA_TYPE.RATES_CLUSTER,
            dateRange: {
                options: [30],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns(): ICustomColumn[] {
        return [{
            name: 'change',
            label: this.$tc('titles.change'),
            value: true,
        }, {
            name: 'score',
            label: this.$tc('titles.score'),
            value: true,
        }, {
            name: 'diff_delta',
            label: this.$tc('titles.diffnum'),
            value: true,
        }, {
            name: 'diff_precent',
            label: this.$tc('titles.diffper'),
            value: true,
        }];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];
        const { chainRatesProviders } = this.providersService;
        const { filters: values = {} } = this.form;

        const choosenProvider = (values.providers
            || this.clusterService.currentRatesProvider) as string;

        filters.push({
            name: 'providers',
            label: this.$tc('titles.provider'),
            value: choosenProvider,
            options: this.providersService.toItemsList(chainRatesProviders),
            disableOnEdit: false,
        });

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '6',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
